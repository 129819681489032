import React,{useReducer,useEffect, useMemo} from "react"
import {  Input, Form, Header,TextArea,Checkbox,Button, Icon} from 'semantic-ui-react'
import {Col,Container,Row,Input as InputBoostrap} from 'reactstrap'
import {initialState,reducer,payConditions} from './reducers/facturaReducer'
import { useSelector } from "react-redux"
import ReactTable from 'react-table-6'
import moment from "moment"
import LoadingOverlay from "react-loading-overlay"
import { useDispatch } from "react-redux"
import {printPOS,isConsumidorFinal} from '../../../Funciones/utils'


//return jsx to render a component that will contain the quantity and description of the item to bill
const Product = ({quantity,description,iva,paymentId,dispatch}) => {
        return (
        <Form.Group>
            <Form.Field width={2}>
                <InputBoostrap  disabled placeholder="Cantidad" value={quantity} onChange={(e)=>dispatch({type:"CHANGE_QUANTITY",payload:{paymentId,quantity:e.target.value}})} style={{fontAlign:"center"}}/>
            </Form.Field>
            <Form.Field width={12}>
                <TextArea placeholder="Descripción" value={description} onChange={(e)=>dispatch({type:"CHANGE_DESCRIPTION",payload:{paymentId,description:e.target.value}})}></TextArea>
            </Form.Field>
            <Form.Field width={2}>
                <InputBoostrap style={{fontAlign:"center"}} disabled placeholder="Iva" value={iva} onChange={(e)=>dispatch({type:"CHANGE_QUANTITY",payload:{paymentId,quantity:e.target.value}})}/>
            </Form.Field>
        </Form.Group>
        )
}

const Facturacion = ({customerBilling})=>{
    const [state, dispatch] = useReducer(reducer, initialState());
    const {reserva,paymentToBill,loadingBill,paymentToBillNow,billList,modal}= useSelector(x=>x.mainReducer)
    const togglePrinter = useSelector(x => x.printerReducer.togglePrinter)
    const billTypes= useSelector(x=>x.billReducer?.billTypes)
    const {printer}= useSelector(x=>x.printerReducer)
    const dispatchRedux=useDispatch()

        useEffect(()=>{
            if (customerBilling){
                dispatch({type:'SET_CUSTOMER',payload:customerBilling});
            }
        },[customerBilling])

    useEffect(()=>{
        if (!modal) {
            dispatch({type:'INIT_STATE'});
        }
        },[modal])
        
    useEffect(()=>{
        //Si es reserva que cargue las bills hechas sino que inicialize
        if(reserva.reservationId){
            dispatchRedux({type:'RELOAD_RESERVATION_BILL',payload:reserva.reservationId})
        }
        else{
            dispatchRedux({type:'SET_BILL_LIST',payload:[]});
        }
    },[reserva])

    useEffect(()=>{
        if(paymentToBill){
            dispatch({type:'SET_PAYMENTS_LIST',payload:[paymentToBill]});
            }
            return ()=>{
                dispatch({type:'SET_PAYMENTS_LIST',payload:[]});
            }
        },[paymentToBill])
    const isConsumidorFinalVal=useMemo(()=>isConsumidorFinal(state.billInfo.total,state.billInfo.payCondition),[state.billInfo.total,state.billInfo.payCondition])

    useEffect(()=>{
        if(paymentToBillNow&&(isConsumidorFinalVal||state.customer.dni)){
            dispatch({type:'SET_PAYMENTS_LIST',payload:[paymentToBillNow]});
            dispatch({type:"PAY_BILL"})
            dispatch({type:'SET_PAYMENTS_LIST',payload:[]});
        }
        return ()=>{
            dispatchRedux({type:'SET_PAYMENT_TO_BILL_NOW',payload:null});
        }
        },[paymentToBillNow])
    const setCustomer=(customer,customerList)=>{
        dispatch({type:"SET_CUSTOMER",payload:customerList.find(x=>x.fullName===customer)})
    }
    const paymentListBilled=billList.map(x=>x.listProduct).flat().map(x=>x.paymentId);
    const paymentColumn = [
        {
        Header: 'Fecha',
        accessor: 'date',
        width:60,
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
        Cell: props =>(moment(props.value).format('DD/MM')),
        filterable: false
            },
        {
        Header: 'Importe',
        accessor: 'amount',
        width:80,
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
            Cell: props =>`$ ${props.value}`,
        filterable: false
            },
        {
        Header: 'Forma',
        width:100,
        accessor: 'method',
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        
        filterable: false
            },
        {
        Header: 'Facturar',
        width:100,
        style:{fontWeight:"bold",fontSize:"1.2rem"},
        headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Checkbox
                checked={state.paymentsList.find(x=>x.paymentId===props.original.paymentId)?true:false}
                onChange={()=>{
                    if (state.paymentsList.find(x=>x.paymentId===props.original.paymentId)?true:false) {
                         //Remove payment from paymentsList
                         dispatch({type:"SET_PAYMENTS_LIST",payload:state.paymentsList.filter(x=>x.paymentId!==props.original.paymentId)})
                    }
                    else{
                        dispatch({type:"SET_PAYMENTS_LIST",payload:[...state.paymentsList,props.original]})
                    }}
                }
            /></div>),
        filterable: false
        }
        ]
    let billColumn = [
        {
            Header: 'Fecha creada',
            accessor: 'date',
            width:150,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(moment(props.value).format('DD/MM/YYYY HH:mm')),
            filterable: false
        },
        {
            Header: 'Tipo factura',
            accessor: 'typeBill',
            width:170,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Condicion',
            accessor: 'payCondition',
            width:170,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Razon social',
            accessor: 'name',
            width:250,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            filterable: false
        },
        {
            Header: 'Total',
            accessor: 'total',
            width:100,
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>`$ ${props.original.listProduct.reduce((a,b)=>a+b.price,0)}`,
            filterable: false
        },
        {
            Header: 'Abrir link',
            accessor: 'billLink', 
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Icon size={"large"} name="copy outline" onClick={()=>{
                window.open(props.value)
                navigator.clipboard.writeText(props.value)
                .then(() => {
                    //console.log('Async: Copying to clipboard was successful!');
                })
                .catch(err => {
                    console.log('Async: Could not copy text: ', err);
                });
            }}/></div>),
            filterable: false
        },]
    //add printer column
    if(printer.isConnected){
        billColumn.push({
            Header: 'Imprimir',
            accessor: 'billLink',
            style:{fontSize:"1.1rem"},
            headerStyle:{fontSize:"1.2rem"},
            Cell: props =>(<div style={{cursor:"pointer"}}><Icon size={"large"} name="print" onClick={()=>{
                const {dateBill,comprobanteNro,cae,dateExpireCae,listProduct,afipQR,payCondition}=props.original;
                printPOS({bill:{dateBill},comprobanteNro,cae,dateExpireCae,listProduct,afipQR,payCondition},"ticketb")
            }
            }/></div>),
            filterable: false
        })
    }

    return(
        <LoadingOverlay active={loadingBill} spinner text='Cargando'>
        <Container>
            <Row>
                <Col md={4}>
                    <Header style={{marginBottom:'5px'}} as='h1'>{isConsumidorFinalVal?"Cons. final":state.customer.fullName}</Header>
                    {!isConsumidorFinalVal&&<Header style={{marginBottom:'5px',marginTop:'0px'}} as='h3'>DNI: {state.customer.dni}</Header>}
                    <Header style={{marginBottom:'5px',marginTop:'0px'}} as='h3'>Email: {state.customer.email}</Header>
                    <Checkbox disabled={!state.customer.email} slider checked={state.billInfo.sendEmail} onChange={(e)=>{
                        dispatch({type:"SET_BILL_INFO",payload:{sendEmail:!state.billInfo.sendEmail}})
                    }} label='Enviar factura por email' />
                    {togglePrinter&&<Checkbox 
                    label={"Imprimir ticket"}
                    slider 
                    disabled={!printer.isConnected}
                    onChange={(e)=>{
                        dispatchRedux({type:"SET_PRINT_TICKET",payload:!printer.printTicket})
                    }} 
                    checked={printer.printTicket}/>}
                    <InputBoostrap autocomplete="off"
                            type="select"
                            value={state.customer.fullName}
                            onChange={(e) => setCustomer(e.target.value,reserva.customersList)} 
                            name="dni" id={"dni"}>
                            {reserva.customersList.map(x=>(<option key={x.fullName} value={x.fullName}>{x.fullName}</option>))}
                    </InputBoostrap>
                    <br/>
                    <ReactTable 
                    defaultPageSize={7}
                    noDataText={'No hay pagos'} 
                    className="-striped -highlight" 
                    filterable 
                    showPagination= {false}
                    
                    loadingText={"Cargando..."}
                    loading={false} columns={paymentColumn}
                    data={reserva?.servicesList.map(x=>x.paymentsList.map(y=>({...y,serviceId:x.serviceId}))??[]).flat().sort(function(a, b){return moment(b.date).diff(moment(a.date))}).filter(x=>!paymentListBilled.find(y=>y==x.serviceId))} />
                    {/* <Header style={{marginTop:'0px'}} as='h3' > {`${state.customer.dni} - ${state.customer.email}`}</Header> */}
                </Col>
                {}
                <Col md={8}>
                    <Row>
                        <Col xs={12} md={4}>
                            <h3>Tipo factura</h3>
                            <InputBoostrap autocomplete="off"
                            type="select"
                            value={state.billInfo.typeBill}
                            onChange={(e) => dispatch({type:"SET_BILL_INFO",payload:{typeBill:e.target.value}})} 
                            name="dni" id={"dni"}>
                            {billTypes.map(x=>(<option key={x} value={x}>{x}</option>))}
                            </InputBoostrap>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Forma de pago</h3>
                            <InputBoostrap autocomplete="off"
                            type="select"
                            value={state.billInfo.payCondition}
                            onChange={(e) => dispatch({type:"SET_BILL_INFO",payload:{payCondition:e.target.value}})} 
                            name="dni" id={"dni"}>
                            {payConditions.map(x=>(<option key={x} value={x}>{x}</option>))}
                            </InputBoostrap>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Fecha emisión</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateBill}
                            onChange={(e) => dispatch({type:"SET_BILL_INFO",payload:{dateBill:e.target.value}})} 
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <h3>Facturado desde</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateFrom}
                            onChange={(e) =>
                                {
                                    //Check if from is before to and if not alert
                                    if (moment(e.target.value).isAfter(state.billInfo.dateTo)) {
                                        alert("La fecha desde no puede ser mayor a la fecha hasta")
                                    }
                                    else{
                                        dispatch({type:"SET_BILL_INFO",payload:{dateFrom:e.target.value}})}
                                    }
                            }
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3>Facturado hasta</h3>
                            <Input autocomplete="off"
                            value={state.billInfo.dateTo}
                            onChange={(e) => {
                                //Check if from is before to and if not alert
                                if (moment(e.target.value).isBefore(moment(state.billInfo.dateFrom))) {
                                    alert("La fecha hasta debe ser mayor a la fecha desde")
                                }
                                else{
                                    dispatch({type:"SET_BILL_INFO",payload:{dateTo:e.target.value}})}

                                }
                            }
                            type="date"
                            name="dni" id={"dni"}></Input>
                        </Col>
                        <Col xs={12} md={4}>
                        </Col>
                    </Row>
              
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <h3>Items</h3>
                                {state.itemsList.map((item,index)=>(<Product key={index} paymentId={item.paymentId} quantity={item.quantity} iva={item.iva} description={item.description} dispatch={dispatch}/>))}
                              
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col xs={12}>
                            <Header as={'h1'} style={{marginBottom:'5px'}}>Importe Total</Header>
                            <Header as={'h1'} style={{marginTop:'5px'}}>$1000</Header>
                        </Col> */}
                        {/*Abajo se va a declarar un boton para pagar factura*/ }
                        <Col xs={12}>
                            <Button 
                            disabled={(!isConsumidorFinal&&!state.customer.dni)||state.billInfo.total==0}
                            fluid={true}
                            color={'teal'}
                            size={'big'}
                            onClick={()=>{
                                dispatch({type:"PAY_BILL"})
                                dispatch({type:'SET_PAYMENTS_LIST',payload:[]});

                            }}>Facturar ${state.billInfo.total}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <br/>
                <Row>
                        <Col>
                            <ReactTable
                            defaultPageSize={7}
                            noDataText={'No hay pagos'} 
                            className="-striped -highlight" 
                            filterable 
                            showPagination= {true}
                            loadingText={"Cargando..."}
                            loading={false} columns={billColumn}
                            data={billList.sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
                            
                        </Col>
                    </Row>
        </Container>
        </LoadingOverlay>

    )
}

export default Facturacion;

// userToken:"8e967ae24f5113e32be5fb2bae20d5f9",
//         apikey:"48929",
//          apitoken:"f28d37722c8b2c5f8bbeccfdd12575e5",
//         documentNumber:"39840232",
//          name:"Santiago Esmoris",
//          email:"santiago.esmoris@gmail.com",
//         sendEmail:true,
//         payCondition:"Contado",
//         pointSell:1,
//         dateFrom:"22/10/2021",
//         dateTo:"24/10/2021",
//         description:"Prueba",
//         totalPrice:"200"}