import React, {useState,useRef, useEffect} from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useDispatch,useSelector,shallowEqual} from 'react-redux'
import moment from 'moment'
import { Auth } from 'aws-amplify';
//import {TinyDatePicker} from 'tiny-date-picker/dist/date-range-picker'
import DateRangePicker from '../components/dormis/extras/dateRangePicker';
import Scheduler from '../components/dormis/main/scheduler';
import Modal from '../components/dormis/modal/modal'
import DisplayFechaModal from '../components/dormis/main/displayFecha'
import { Label,Button } from 'semantic-ui-react'
import {Col} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay';
import {ActualizarListaReservas,RefrescarPrecioYDispo,CalcularCheckoutTime,ActualizarMapInfo} from '../Funciones/utils'
import { Menu,Icon,Progress,Sidebar,Segment,Search} from 'semantic-ui-react'
import StepWizard from 'react-step-wizard';
import TablaRegistro from '../components/dormis/listado/tablaRegistro'
import Precios from '../components/dormis/precio/precioDormis'
import Configuracion from '../components/dormis/configuracion/configuracion'
import {Collapse} from 'react-collapse';
import Caja from '../components/dormis/caja/caja'
import Estadisticas from '../components/dormis/estadisticas/main';
import Mapa from '../components/dormis/mapa/mapa';
import TipoMenu from '../components/dormis/extras/tipoMenu'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer} from 'react-notifications';
import useOnlineStatus from '@rehooks/online-status';
//ACTIONS
import setReserva from '../redux/actions/setReserva'
import setModal from '../redux/actions/setModalReserva'
import setIsMobile from '../redux/actions/setIsMobile'
import setRegularCustomer from '../redux/actions/setRegularCustomer'


const EjecutarActualizacion=async (dispatch)=>{
    dispatch({type:"INIT_PRICES",payload:{from:moment().add(-60,'days').format('YYYY-MM-DD'),to:moment().add(200,'days').format('YYYY-MM-DD')}})
    dispatch({type:"LOAD_USER_CONFIG"})	
    //await ActualizarListaDeptos(setLoading,dispatch)
    //await ActualizarListaReservas(setLoading,dispatch)
    ActualizarMapInfo(dispatch);

    //Cada 55 min actualiza el token
   /* const refrescarToken=()=>{
      setTimeout(()=>{
      window.location.reload(false);
    },3300000)}
   refrescarToken();*/
}

const Calendario=({dispatch,withoutPlace,fecha,listaReservas,setLoading})=>{
  const [fechaInicio,setFechaInicio]=useState(null)
  const [cantidadDias,setCantidadDias]=useState(90)
  const [mostrarReservas,setMostrarReservas]=useState("confirmadas")
  const [rangoFechasOpened,setRangoFechasOpened]=useState(false)
  const startRef=useRef();
  const endRef=useRef();
  return(
  <React.Fragment>
   {rangoFechasOpened&&<Collapse isOpened={rangoFechasOpened}>
          <Col xs={12}>
            <DateRangePicker startRef={startRef} endRef={endRef}/>
            <Button basic color='blue' size={"big"} onClick={()=>{
              const inicioFixed=startRef.current.value.split("/").reverse().join("-")
              const salidaFixed=endRef.current.value.split("/").reverse().join("-")
              setFechaInicio(inicioFixed);
              dispatch({type:'RELOAD_RESERVATION_LIST_SCHEDULER',payload:{startDate:inicioFixed,endDate:salidaFixed}})
              setCantidadDias(moment(salidaFixed).diff(moment(inicioFixed),"days"))}}>
              Buscar
            </Button>
          </Col>
      </Collapse>}
    <Menu pointing secondary>
      <Menu.Item
        position='right'
        name='Rango'
        active={rangoFechasOpened}
        onClick={()=>{setRangoFechasOpened(!rangoFechasOpened)}}
      />
    </Menu>
      {<Scheduler disable={mostrarReservas!="confirmadas"} withoutPlace={withoutPlace} listaReservas={listaReservas.filter(x=>x.state!='cancelada')} setLoading={setLoading} fechaInicio={fechaInicio} cantidadDias={cantidadDias}/>}
      <Modal/>
      <DisplayFechaModal fecha={fecha} mostrarFull={true}/>
  </React.Fragment>
  )}

const Index=()=>{
  const [mainMenu,setMainMenu]=useState("calendario")
  const [stepWizard,setStepWizard]=useState({})
  const [collapse, setCollapse] = useState(false);
  const [stateLabel, setStateLabel] = useState("");
  const [search, setSearch] = useState();
  const withoutPlace = useSelector(state => state.mainReducer.withoutPlace);
  const currentBuildNumber = useSelector(state => state.mainReducer.currentBuildNumber);
  const statisticsDate = useSelector(state => state.mainReducer.statisticsDate);
  const listaGeneralReservas = useSelector(state => state.mainReducer.listaGeneralReservas);
  const listaGeneralDeptos = useSelector(state => state.mainReducer.listaGeneralDeptos);
  const sidebarOpened = useSelector(state => state.mainReducer.sidebarOpened);
  const searchResult = useSelector(state => state.mainReducer.searchResult);
  const searchLoading = useSelector(state => state.mainReducer.searchLoading);
  const paymentListDay = useSelector(state => state.mainReducer.paymentListDay);
  const diaPlaya = useSelector(state => state.mainReducer.diaPlaya);
  const changeDateTime = useSelector(state => state.mainReducer.changeDateTime);
  const loadingBar = useSelector(state => state.mainReducer.loadingBar);
  const isAdmin = useSelector(state => state.mainReducer.isAdmin);
  const tipoPrecio = useSelector(state => state.mainReducer.tipoPrecio);
  const loading = useSelector(state => state.mainReducer.mainLoading);
  const fecha = useSelector(state => state.mainReducer.fecha);
  const listaReservas = useSelector(state => state.mainReducer.listaReservas);
  const isMobile = useSelector(state => state.mainReducer.isMobile);
  const mapLink = useSelector(state => state.mainReducer.mapInfo.mapLink);
const dispatch=useDispatch();
  useEffect(()=>{
    const mobile=window.innerWidth<600
    dispatch(setIsMobile(mobile))
    /*fetch(`https://yxt7f6hso6.execute-api.us-east-1.amazonaws.com/DEV/demo?type=nuevo visitante&email=&mobile=${mobile}`)*/
    //SetSubscriptions(dispatch);
    const val= localStorage.getItem('regularCustomer');
    dispatch(setRegularCustomer(val));
    EjecutarActualizacion(dispatch);
    dispatch({type:'SET_STATISTICS_DATE',payload:moment().format('YYYY-MM-DD')})
    const reloadTime=()=>{setTimeout(()=>{
      reloadTime();
      dispatch({type:'RELOAD_RESERVATIONS'});
      dispatch({type:'RELOAD_PRICE_LIST'});
      
    },120000)}
    reloadTime();
  },[])
  
  useEffect(()=>{
    dispatch({type:'INIT_CASH_MOVEMENT'})
  },[])

useEffect(() => {
    dispatch({type:'RELOAD_STATISTICS_BY_MONTH'})
}, [statisticsDate])
  useEffect(() => {
    const actualizarApp= ()=>{
      /*ActualizarListaReservas(()=>{},dispatch)
      RefrescarPrecioYDispo(dispatch)*/
    //SetSubscriptions(dispatch);
    }
     window.addEventListener("webkitvisibilitychange", actualizarApp);
     return () => {
        window.removeEventListener("webkitvisibilitychange", actualizarApp);
     };
  },[]);
  useEffect(()=>{
    //dispatch({type:'LISTA_RESERVAS_BY_TYPE'})
  },[listaGeneralReservas])
  useEffect(()=>{
    dispatch({type:'LISTA_DEPTOS_BY_TYPE'})
  },[listaGeneralDeptos])
  useEffect(()=>{
    dispatch({type:'PAGE_LISTINGS',payload:0})
  },[])

  useEffect(()=>{
    dispatch({type:'RELOAD_CHECKOUT_TIME'})
    dispatch({type:'LISTA_RESERVAS_BY_TYPE'})
    dispatch({type:'LISTA_DEPTOS_BY_TYPE'})
    dispatch({type:'RELOAD_STATISTICS_BY_DATE'})
    dispatch({type:'RELOAD_PAYMENT_LIST_STATISTICS'})
    dispatch({type:'RELOAD_STATISTICS_BY_MONTH',payload:true})

  },[tipoPrecio])

  useEffect(()=>{
    dispatch({type:'RELOAD_PAYMENT_LIST_STATISTICS'})
  },[paymentListDay])
  const isOnline = useOnlineStatus();

  useEffect(() => {
    const recheckMobile=()=>{  
    const mobile=window.innerWidth<600
    dispatch(setIsMobile(mobile))
  };
     window.addEventListener ("resize",recheckMobile , true);

     const mobile=window.innerWidth<600
     dispatch({payload:mobile,type:'SET_FIRST_MOBILE'})
     return () => {
        window.removeEventListener("resize", recheckMobile);
     };
  },[]);

  useEffect(()=>{
    Auth.currentSession().then(x=>{
      dispatch({type:'SET_SESSION',payload:x})
      dispatch({type:'SET_ADMIN',payload:x?.accessToken?.payload["cognito:groups"].filter(x=>x=="superUser").length!=0})
      dispatch({type:'SET_CAMPING_USER',payload:x?.accessToken?.payload["cognito:groups"].find(x=>x.split("-").length==2).split("-")[1]})
      dispatch({type:'SET_CURRENT_USER',payload:x.accessToken.payload.username})
    })
    dispatch({type:'TEST',payload:"bool"})
    
  },[])
  useEffect(()=>{
    if (isOnline) {
      dispatch({type:'RELOAD_RESERVATIONS',payload:""})
      ActualizarListaReservas(()=>{},dispatch)
      RefrescarPrecioYDispo(dispatch)
    }
  },[isOnline])
  useEffect(()=>{
    dispatch({type:'RELOAD_CASH_MOVEMENT'})
  },[currentBuildNumber])

  useEffect(()=>{
    if (search!=undefined&&search.trim()!=""&&search.length>2) {
      dispatch({type:'SEARCH',payload:{searchThing:search}})
    }  
  },[search])
  
  const setLoading=(bool)=>{dispatch({type:'MAIN_LOADING',payload:bool})}
  const nuevaReserva=()=>{
      dispatch({type:'NEW_RESERVATION'})
    }
  const abrirPrecios=()=>{
    const checkoutEstimated=moment().add(-changeDateTime,'hours').add(1,'days').set({hour:CalcularCheckoutTime()-3,minute:0,second:0,millisecond:0}).format();
    const checkinEstimated=moment().add(-changeDateTime,'hours').set({hour:CalcularCheckoutTime()-3,minute:0,second:0,millisecond:0}).format();
    const nuevaReserva= {
      guests: 2,
      children: 0,
      checkoutEstimated,
      checkinEstimated,
      servicesList:[],
      customersList:[],
      vehiclesList:[],
      nuevaReserva:true,
      calcularPrecio:true,
      nights:diaPlaya?0:1,
      state: "",
      way: "Presencial",
      roomsList:[],
}
      dispatch(setReserva(nuevaReserva))
      dispatch(setModal(true));
    }
    const goTo=(state)=>{
      setMainMenu("listado");
      stepWizard.goToStep(2);
      setStateLabel(state)
    }
  return(
    <React.Fragment>
      <LoadingOverlay
      active={loading}
      spinner
      text='Buscando'
      >
      <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            vertical
            onHide={() =>isMobile?dispatch({type:'SET_SIDEBAR',payload:false}):false}
            visible={sidebarOpened}
            className={'sidebar'}
            animation={'push'}
            width="thin"
          >
            <Menu.Item as='a' className='newReservation' onClick={()=>{nuevaReserva()}}>
              <Icon name='plus' color='teal' />
              Nuevo
            </Menu.Item>
            <Menu.Item as='a' active={mainMenu === 'calendario'} onClick={()=>{stepWizard.goToStep(1);setMainMenu("calendario")}}>
              <Icon name='calendar alternate outline' />
              Calendario
            </Menu.Item>
            <Menu.Item as='a' active={mainMenu === 'listado'} onClick={()=>{stepWizard.goToStep(2);setMainMenu("listado")}}>
              <Icon name='list' />
              Listado
            </Menu.Item>
            {mapLink&&tipoPrecio=="camping"&&<Menu.Item as='a' active={mainMenu === 'mapa'} onClick={()=>{stepWizard.goToStep(3);setMainMenu("mapa")}}>
              <Icon name='map outline'/>
              Mapa
            </Menu.Item>}
            <Menu.Item as='a' active={mainMenu === 'caja'} onClick={()=>{stepWizard.goToStep(4);setMainMenu("caja")}}>
              <Icon name='money bill alternate outline'/>
              Caja
            </Menu.Item>
            {isAdmin&&<Menu.Item as='a' active={mainMenu === 'estadisticas'} onClick={()=>{stepWizard.goToStep(5);setMainMenu("estadisticas")}}>
              <Icon name='line graph'/>
              Estadisticas
            </Menu.Item>}
            <Menu.Item as='a' active={mainMenu === 'precios'} onClick={()=>{stepWizard.goToStep(6);setMainMenu("precios")}}>
              <Icon name='dollar sign' />
              Precios
            </Menu.Item>
            <Menu.Item as='a' onClick={()=>{abrirPrecios()}} >
              <Icon name='calculator'/>
              Calculadora
            </Menu.Item>
            <Menu.Item as='a' onClick={()=>{
              if (window.confirm('¿Seguro que desea cerrar sesion?')) {
                localStorage.setItem('listaGeneralReservas', null);
                Auth.signOut()
              }
            }} >
              <Icon name='sign-out' color="orange"/>
                Salir
            </Menu.Item>
            {/* {isMobile&&<Menu.Item as='a' className='newReservation' onClick={()=>{
              dispatch({type:'SET_SIDEBAR',payload:!sidebarOpened})
            }}>
              <Icon name='reply' color='teal' />
              Ocultar
            </Menu.Item>} */}
          </Sidebar>
          <Sidebar.Pusher>
          
        <Col style={{padding:!sidebarOpened?0:""}} xs={sidebarOpened?11:12}>
        <TipoMenu isMobile={isMobile} setCollapse={setCollapse} collapse={collapse}/>
        {isMobile&&<Search
          noResultsMessage={'No se encontraron registros'}
          loading={searchLoading}
          aligned={'center'}
          style={{margin:'auto'}}
          size={isMobile?'large':''}
          minCharacters={'3'}
          onSearchChange={(e)=>{setSearch(e.target.value)}}
          results={searchResult}
          value={search}
        />}

        <Segment>
        <Col xs={12}>
          <Label  color='blue' horizontal style={{cursor:'pointer'}} onClick={()=>goTo("fllegar")}>
            Reserva
          </Label>
          <Label  color='green' horizontal style={{cursor:'pointer'}} onClick={()=>goTo("alojados")}>
            Checkin
          </Label>
          <Label  color='yellow' horizontal style={{cursor:'pointer'}} onClick={()=>goTo("adeudados")}>
            Adeudado
          </Label>
          <Label  color='brown' horizontal style={{cursor:'pointer'}} onClick={()=>goTo("todas")}>
            Checkout
          </Label>
        </Col>

          <Progress error={!isOnline} success={!loadingBar} style={{marginBottom:'10px'}} percent={loadingBar?50:100}  active={loadingBar}></Progress>
          {//Center text that says no internet
          !isOnline&&
            <Label style={{textAlign:'center'}}color='red' size='large'>
              No hay conexión a internet
            </Label>}
          
          {/* {console.log(listaReservas.find(x=>x.roomsList.find(y=>y.number=="5-34")))} */}
            <StepWizard initialStep={1} instance={(e)=>setStepWizard(e) }>
              <Calendario isMobile={isMobile} checkoutTime={CalcularCheckoutTime()} dispatch={dispatch} listaReservas={listaReservas} fecha={fecha} setLoading={setLoading} withoutPlace={withoutPlace}/>
              <TablaRegistro dispatch={dispatch} listaReservas={listaReservas} stateLabel={stateLabel}/>
              <Mapa fecha={moment().format('YYYY-MM-DD')}/>
              <Caja/>
              <Estadisticas/>
              <Precios setLoading={setLoading}/>
              <Configuracion/>
            </StepWizard>
            </Segment>
          </Col>
          </Sidebar.Pusher>
          </Sidebar.Pushable>
         </LoadingOverlay>
        <NotificationContainer/>
        
    </React.Fragment>
  )
}

const Wrapper=()=>{
  return(
  <Layout>
    <SEO title="Inicio" />
    <Index></Index>
  </Layout>)
  }

export default Wrapper


