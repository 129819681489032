import React,{useState,useEffect} from "react"
import ReactTable from 'react-table-6'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import { Col,Row,Container,Button as ButtonR} from 'reactstrap';
import {AgregarYPagarServicios,AgregarServicios,PuedeReservar, printPOS, DevolverComponentesParaJSX} from "../../../Funciones/utils"
import {connect} from 'react-redux'
import  Pagos  from './pagos'
import { Button,Input as SemanticInput,Divider,Icon,Loader} from 'semantic-ui-react'
import Precios from './precios'
import ConfirmarPago from './confirmarPago'


export const Caja = ({printTicket,customerBilling,setCustomerBilling,billList,loadingBill,goToFacturacion,isAdmin,billSession,dispatch,reserva:ingreso,isMobile,paymentLoading,calcularPrecio}) => {
  const [componentes,setComponentes]=useState(0)
  const [costo,setCosto]=useState(0)
  const [loading,setLoading] =useState(false);
  const [service,setService] =useState(null);
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [fechaSalida, setFechaSalida] = useState("");
  const [forma, setForma] = useState("");
  const [nombreCuenta, setNombreCuenta] = useState("");
  const [servicio, setServicio] = useState();
  const [openPago, setOpenPago] = useState(false);
  const [openPagoLuego, setOpenPagoLuego] = useState(false);
  const [makeBill, setMakeBill] = useState(true);

    useEffect(()=>{
      setNombreCuenta(ingreso.nuevaReserva?'':ingreso?.customersList[0]?.fullName)
      setComponentes(DevolverComponentesParaJSX(ingreso.vehiclesList,ingreso.customersList,ingreso,ingreso.servicesList))
    },[ingreso])

    const serviciosLimpios=ingreso.servicesList.filter(x =>!x.canceled);
    const primerPago=serviciosLimpios.length == 0||
    (serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso");

    var paymentColumn = [
    {
    Header: '',
    accessor: 'date',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
   
    Cell: props =>(moment(props.value).format(isMobile?'DD/MM':'DD/MM/YYYY - hh:mm a')),
    filterable: false
      },
    {
    Header: '',
    accessor: 'isRefund',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
  
    filterable: false,
    Cell: props =>(props.value?"Devolucion":"Pago"),
      },
    {
    Header: '',
    accessor: 'amount',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: '',
    accessor: 'method',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
 
    filterable: false
      },
    ]
    var serviceColumn = [
    {
    Header: isMobile?'Creada':'Fecha de creacion',
    accessor: 'date',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    width:isMobile?70:200,
    Cell: props =>(moment(props.value).format(isMobile?'DD/MM':'DD/MM - HH:mm')),
    filterable: false
      },

    {
    Header: 'Descripcion',
    accessor: 'description',
    width:isMobile?120:340,
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    filterable: false,
    Cell: props =>(<div style={{textAlign:"center"}}>{props.value}</div>),
      },
    {
    Header: 'Valor',
    accessor: 'cost',
  
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Saldo',
    accessor: 'due',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Pagar',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>(<div style={{cursor:props.original.canceled?"":props.row.due>0?"pointer":""}}><Icon color={props.original.canceled?'grey':'green'} onClick={()=>{if(props.row.due>0&&!props.original.canceled){setService(props.original)}}} size='large' style={{marginLeft:"5px"}} name={props.original.canceled?'minus':props.row.due>0?'plus square':`check`} /></div>),
    filterable: false
      }
    ]
//Solo los que tienen facturacion
if (billSession.toggleBilling) {
  serviceColumn=[...serviceColumn,    
    {
      Header: 'Facturar',
      style:{fontWeight:"bold",fontSize:"1.2rem"},
      headerStyle:{fontSize:"1.2rem"},
       Cell: props =>{
        const isBilled=billList.map(x=>x.listProduct).flat().find(x=>x.paymentId==props.original.serviceId);
        
        return(
        loadingBill?<Loader active inline />:<div style={{cursor:(props.original.paymentsList.length==0||isBilled)?"":"pointer"}}><Icon 
       disabled={props.original.paymentsList.length==0}
       onClick={()=>{
            goToFacturacion();
            dispatch({type:'SET_PAYMENT_TO_BILL',payload:props.original.paymentsList[0]})
        }} size='large' style={{marginLeft:"5px"}} name={isBilled?'check':'file alternate outline'}/></div>)},
      filterable: false
        }]
}
else if(printTicket){
  serviceColumn.push({
    Header: 'Imprimir',
    accessor: '',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props =>(<div style={{cursor:props.original.paymentsList.length>0?"pointer":""}}><Icon size={"large"} name={props.original.paymentsList.length>0?"print":"minus"} onClick={()=>{
      if (props.original.paymentsList.length>0) {
        printPOS(props.original,"ticketx")
      }
      
    }
    }/></div>),
    filterable: false
})
}

//Solo los superUser pueden cancelar pagos
if (isAdmin) {
  serviceColumn=[...serviceColumn,    
    {
    Header: 'Cancelar',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>(<div style={{cursor:"pointer"}}><Icon color='red' onClick={()=>{
       if(window.confirm(`¿Seguro que deseas ${props.original.canceled?"reactivar":"cancelar"} el pago con valor ${props.row.cost}?`))
          {dispatch({type:'CANCEL_SERVICE',payload:{dispatch,reservationId:ingreso.reservationId,serviceId:props.original.serviceId,isCanceled:!props.original.canceled}})
        }}} size='large' style={{marginLeft:"5px"}} name='x'/></div>),
    filterable: false
      }]
}
const quantity=servicio?servicio.quantity:1;

 return (
    <React.Fragment>
      <LoadingOverlay
      active={loading||paymentLoading}
      spinner
      text='Agregando'
      >
      <ConfirmarPago 
      makeBill={makeBill}
      setMakeBill={setMakeBill}
      customersList={ingreso.customersList?ingreso.customersList.filter(x=>x.dni):[]}
      customerBilling={customerBilling}
      setCustomerBilling={setCustomerBilling}
      onPay={()=>{
          const fSalidaAux=`${fechaSalida}T${ingreso.checkoutEstimated.split('T')[1]}`;
          const puedeReservar=PuedeReservar(ingreso.checkinEstimated,fSalidaAux,ingreso);
          if (forma=="") {
            alert("falta agregar forma de pago")
          }
          else if(!puedeReservar.resultado&&!window.confirm(`Ya hay un registro en el lugar ${puedeReservar.habitacion.number} para la fecha\n¿Desea continuar de todos modos?`)){

          }
          else{
            setLoading(true)
            const ingresoAux=JSON.parse(JSON.stringify(ingreso))
            ingresoAux.checkoutEstimated=moment(fSalidaAux).format();
            let quantityAux=(primerPago&&(serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso"))?ingreso.nights-servicio.quantity:servicio.quantity;
            if (quantityAux<0) {
              quantityAux=-quantityAux;
            }
            
            AgregarYPagarServicios(ingreso.reservationId,servicio,dispatch,setLoading,quantityAux,{amount:costo,isRefund:false,date:moment().format(),method:forma},makeBill,()=>{})
            setOpenPago(false)
          }
      }} loading={loading} setForma={setForma} isMobile={isMobile} precio={costo} open={openPago} setOpen={setOpenPago} nombreCuenta={nombreCuenta} fechaSalida={fechaSalida} noches={quantity} forma={forma} descripcion={servicio?.description} />
        <ConfirmarPago onPay={() => {
            const fSalidaAux = `${fechaSalida}T${ingreso.checkoutEstimated.split('T')[1]}`;
            const puedeReservar = PuedeReservar(ingreso.checkinEstimated, fSalidaAux, ingreso);
            if (servicio.costo == "") {
                alert("falta agregar el valor")
            }
            else if (!puedeReservar.resultado && !window.confirm(`Ya hay un registro en el lugar ${puedeReservar.habitacion.number} para la fecha\n¿Desea continuar de todos modos?`)) {
                
            }
            else {
                setLoading(true)
                const ingresoAux = JSON.parse(JSON.stringify(ingreso))
                ingresoAux.checkoutEstimated = moment(fSalidaAux).format();
                let quantityAux=(primerPago&&(serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso"))?ingreso.nights-servicio.quantity:servicio.quantity;
                if (quantityAux<0) {
                  quantityAux=-quantityAux;
                }    
                console.log(ingresoAux)           
                AgregarServicios(ingreso.reservationId, servicio, dispatch, setLoading, quantityAux,() => { })
                setOpenPagoLuego(false)
            }
        }} loading={loading} isMobile={isMobile} setForma={setForma} precio={costo} open={openPagoLuego} setOpen={setOpenPagoLuego} nombreCuenta={nombreCuenta} fechaSalida={fechaSalida} noches={quantity} forma={'pagar luego'} descripcion={servicio?.description} />
          <Pagos setService={setService} service={service} loading={loading} setLoading={setLoading} />
          <Precios
          vistaCompleta={true}
          calcularPrecio={calcularPrecio}
          fechaIngreso={fechaIngreso} setFechaIngreso={setFechaIngreso}
          servicesList={ingreso.servicesList} ingreso={ingreso} componentes={componentes} setComponentes={setComponentes} setFechaSalida={setFechaSalida} setServicio={setServicio} costo={costo} setCosto={setCosto}/>
          {!calcularPrecio&&<>
          <Divider horizontal>-</Divider>
          <br></br>
          <Container>
            <Row>
              <Col style={{display: "contents"}}>
              <Button.Group vertical={isMobile} style={{margin: "auto"}} widths={ingreso.state=='confirmada'?'3':'2'}>
                <Button  size={isMobile?'massive':'big'} color='teal' fluid onClick={()=>{
                    setOpenPago(true);}}>{`Pagar ${quantity==0?'pasar el dia':`${quantity} ${(quantity == 1) ? "noche" : "noches"}`} $${costo}`}</Button>
                     {!isMobile&&<Button.Or text={"o"}/>}
                <Button  size={isMobile?'massive':'big'} color='grey' fluid onClick={()=>{
                    setOpenPagoLuego(true);}}>{`Pagar luego`}</Button>
                </Button.Group>
              </Col>
            </Row>
           </Container>
            <ReactTable
             getTrProps={(state, rowInfo, column) => {  
                if (rowInfo) {
                  return {
                    style: {
                      textDecoration: rowInfo.original.canceled? 'line-through' : ''
                          }
                        }
                 }
                else{
                    return{}
                  }
              }} 
            defaultPageSize={5}
            noDataText={'No hay pagos'} 
            className="-striped -highlight" 
            filterable 
            loadingText={"Cargando..."} 
            
            loading={false} columns={serviceColumn} 
            SubComponent={row => {
              
              return (
                <ReactTable 
                defaultPageSize={7}
                noDataText={'No hay pagos'} 
                className="-striped -highlight" 
                filterable 
                showPagination= {false}
                loadingText={"Cargando..."}
                loading={false} columns={paymentColumn}
                data={row.original.paymentsList.sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
              )
            }}
            data={ingreso.servicesList.filter(x=>x.name!="preingreso").sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
            <br/>
            <Row>
              {/* <Col xs={4} >
                <h3 style={{textAlign:"center"}}>Noches pagas</h3>
              </Col>
             <Col xs={8}>
                <h3>{ingreso.nochesPagas}</h3>
          </Col>*/}
            </Row>
            <Row>
              <Col xs={4} >
                <h3>Total abonado ${ingreso.servicesList.filter(x=>!x.canceled).map(x=>x.paid).reduce((a,b) => a + parseInt(b), 0)}</h3>
              </Col>
              <Col xs={8}>
                <h4></h4>
              </Col>
            </Row>
        
            <Row>
              {/*<Col xs={4} >
                <h4 style={{textAlign:"center"}}>Total Cta corriente</h4>
            </Col>
              <Col xs={8}>
                <h4>${ingreso.listaPagos.filter(x=>x.condicion=="Cta/cte").map(x=>x.importe).reduce((a,b) => a + b, 0)}</h4>
              </Col>*/}
            </Row></>}
            </LoadingOverlay>
      </React.Fragment>
   
    )
}



export default connect(state=>({loadingBill:state.mainReducer.loadingBill,
  billList:state.mainReducer.billList,
  printTicket:state.printerReducer.printer?.printTicket,
  billSession:state.billReducer,
  isAdmin:state.mainReducer.isAdmin,
  tipoPrecio:state.mainReducer.tipoPrecio,
  session:state.mainReducer.session,
  paymentLoading:state.mainReducer.paymentLoading,
  listaDeptos:state.mainReducer.listaDeptos,
  refrescarDispoYPrecios:state.mainReducer.refrescarDispoYPrecios,
  precios:state.mainReducer.precios,
  isMobile:state.mainReducer.isMobile}),null)(Caja)

