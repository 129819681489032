import { isConsumidorFinal } from '../../../../Funciones/utils'
import moment from 'moment'
import store from '../../../../redux/store'

export const payConditions=["Contado","Cuenta corriente","Transferencia Bancaria","Tarjeta de crédito","Tarjeta de débito",
"vto 5 dias","vto 10 dias","vto 15 dias","vto 20 dias","vto 30 dias","vto 60 dias","vto 90 dias","otra"]
// export const billTypes=["Factura A",
// "Factura B",
// "Factura C","Nota de credito A","Nota de credito B",
// "Factura no valida en AFIP","Nota de credito no valida en AFIP",
// "Factura C","Factura M","Factura E"]

export const initialState=() => ({
  customer: {},
  paymentsList: [],
  loading: false,
  itemsList: [],
  reservation:{},
  type:'',
  billInfo:{
    typeBill: 'Factura B',
    payCondition: 'Contado',
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().add(1,'days').format('YYYY-MM-DD'),
    sendEmail: false,
    total: 0,
    dateBill:moment().format('YYYY-MM-DD')
  }}
);


export function reducer(state, action) {
  const {billList,reserva}=store.getState().mainReducer;
    switch (action.type) {
      case 'INIT_STATE':
        return {...initialState()};
      case 'SET_CUSTOMER':
        return {...state,customer:action.payload,billInfo:{...state.billInfo,sendEmail:action.payload.email?true:false}};
      case 'SET_BILL_INFO':
        if (action.payload.typeBill === 'Factura C'||
          action.payload.typeBill === 'Factura B') {
          const itemsList = state.itemsList.map(item => {
            item.iva = action.payload.typeBill === 'Factura C' ? 0 : 21;
            return item;
          });
          return {...state, itemsList, billInfo:{...state.billInfo,...action.payload}}; 
        }
        else{
          return {...state,billInfo:{...state.billInfo,...action.payload}};
        }
      case 'CHANGE_DESCRIPTION':
        return {...state,itemsList:state.itemsList.map(item=>item.paymentId===action.payload.paymentId?{...item,description:action.payload.description}:item)};
      case 'SET_PAYMENTS_LIST':
        const servicesList=reserva.servicesList.filter(service=>service.paymentsList.find(payment=>action.payload.find(payment2=>payment2.paymentId===payment.paymentId)));
        const nights=billList.length>0?reserva.servicesList.length>0?servicesList.reduce((acc,service)=>acc+service.quantity,0):1:1;
        const dateFrom=billList.length>0?calculateDateFrom(reserva.checkinEstimated,billList):moment(reserva.checkinEstimated).format('YYYY-MM-DD');
        const dateTo=billList.length>0?calculateDateTo(dateFrom,nights,billList):moment(reserva.checkoutEstimated).format('YYYY-MM-DD');
        const payConditionAux = action.payload.length > 0 ? choosePayCondition(action.payload[0].method) : 'Contado';
        const type=store.getState().mainReducer.tipoPrecio;
        //days to add reflects the number of days to add to calculate fecha entrada
        let daysToAdd=servicesList.reduce((acc,service)=>([...acc,(service.quantity+acc[acc.length-1])]),[0])
        //Item list contain description and quantity for each item in the bill
        const itemsList = servicesList.length > 0 ? servicesList.map((service,index) => {
          const item = {
            price:service.cost,
            paymentId: service.serviceId,
            description: `Estadia ${type} ${service.description}.\nFecha de entrada ${moment(dateFrom).add(daysToAdd[index],'days').format('DD/MM/YYYY')}`,
            quantity: 1,
            iva:state.typeBill==("Factura C"||"Factura T")?0:21
          };
          return item;
        }) : [];
        return {...state,
          paymentsList:action.payload,itemsList,billInfo:
          {...state.billInfo,
            payCondition:payConditionAux,
            dateFrom,
            dateTo,
            total:action.payload.reduce((total,payment)=>total+parseInt(payment.amount),0)}};
      case 'PAY_BILL':
        //Check if all billInfo fields are set and dateFrom and to are valid dates and from is before to and bring alert for all posible errors
        if(state.billInfo.typeBill && state.billInfo.payCondition && state.billInfo.dateFrom && state.billInfo.dateTo && state.billInfo.total>0){
          if(moment(state.billInfo.dateFrom).isSameOrBefore(moment(state.billInfo.dateTo))){
            const consumidorFinal=isConsumidorFinal(state.billInfo.total,state.billInfo.payCondition)
            const documentNumber=consumidorFinal?0:state.customer.dni;
            store.dispatch({type:'MAKE_BILL',payload:
            {...state.billInfo,
              listProduct:state.itemsList,
              name:consumidorFinal?"Consumidor final":state.customer.fullName,
              email:state.customer.email,
              documentNumber:documentNumber,
              reservationId:reserva.reservationId,
            }}); 
            return state
          }else{
            alert('La fecha de inicio debe ser menor o igual a la de fin')
          }
        }
        else{
           alert('Debe completar todos los datos de la factura')
        }
        return state;
      default:
        throw new Error();
    }
  }
const choosePayCondition=(payFormat)=>{
  switch(payFormat.toLowerCase()){
    case 'Mercadopago'.toLowerCase():
      return 'otra'
    case 'Tarjeta-Web'.toLowerCase():
      return 'Tarjeta de crédito'
    case 'Tarjeta de débito'.toLowerCase():
      return 'Tarjeta de débito'
    case 'Tarjeta de crédito'.toLowerCase():
      return 'Tarjeta de crédito'
    case 'transferencia bancaria':
      return 'Transferencia Bancaria'
    default:
    return 'Contado';
    }
  }
const calculateDateFrom=(checkinEstimated,billList)=>{
  if(billList.length>0){
    const mostRecentBill=billList.sort((a,b)=>moment(b.date).diff(moment(a.date)))[0];
    const dateFrom=moment(mostRecentBill.dateTo).format('YYYY-MM-DD');
    return dateFrom;
  }
  else{
    return checkinEstimated;
  }
}
const calculateDateTo=(from,nights,billList)=>{
  if(billList.length>0){
    const mostRecentBill=billList.sort((a,b)=>moment(b.date).diff(moment(a.date)))[0];
    const dateTo=moment(mostRecentBill.dateTo).add(nights,'days').format('YYYY-MM-DD');
    return dateTo;
  }
  else{
    return moment(from).add(1,'days').format('YYYY-MM-DD');
  }
}
