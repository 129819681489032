import React, { useState, useEffect,useCallback,useMemo,useReducer,useRef } from "react"
import { HabitacionesDisponibles, CambiarEstadoReserva, MandarFormularioDepto, PuedeReservar, SacarDeLista, GetListaHorarios } from '../../../Funciones/utils'
import moment from 'moment'
import { connect } from 'react-redux'
import { Col, Row, Input, Container } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Normalizar, ParseScan,DevolverComponentesParaJSX, EsComponenteExtra,EsLugar,EsVehiculo } from '../../../Funciones/utils'
import TablaVehiculos from './tablas/tablaVehiculos'
import TablaPersonas from './tablas/tablaPersonas'
import { Button as ButtonSemantic, Icon,Dropdown } from 'semantic-ui-react'
import places from 'places.js'
import 'react-phone-input-2/lib/style.css'
import Precios from './precios'
import ConfirmarPago from './confirmarPago'
import {initialState,reducer} from './reducers/reservaReducer'

Array.prototype.unique = function (a) {
  return function () { return this.filter(a) }
}(function (a, b, c) {
  return c.indexOf(a, b + 1) < 0
});


const MandarIngreso = (roomsList, listaPersonas, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,service, setLoading, dispatch, testFunction,makeBill, goToPayment, onComplete, sobrevender, withoutPlace) => {
  if (
    moment(fechaIngreso).isValid() == "" ||
    moment(fechaSalida).isValid() == ""
  ) {
    alert("Alguna de las fechas no tiene formato valido")
  }
  else if (
    fechaIngreso.trim() == "" ||
    fechaSalida.trim() == ""
  ) {
    alert("Faltan completar fechas")
  }
  else if (roomsList.length == 0 && !withoutPlace) {
    alert("Falta poner lugar")
  }
  else if (listaPersonas.length == 0) {
    alert("Debes cargar al menos una persona en el registro")
  }
  else if (listaPersonas.filter(x=>x.fullName).length == 0) {
    alert("Todas las personas deben tener nombre")
  }
  else if (estado == "") {
    alert("Falta agregar si es reserva o checkin")
  }
  else {
    const customersList = listaPersonas.map(x => {
      const { age,uniqueId, ...s } = x
      return s;
    });
    ingreso.checkinEstimated = moment(fechaIngreso).format()
    ingreso.checkoutEstimated = moment(fechaSalida).set({hour: hora.split(':')[0], minute: 0, second: 0, millisecond: 0 }).add(-3, 'hours').format()
    const vehiclesList = vehiculos.map(x => {
      const { tableData, ...s } = x
      return s;
    });
    const servicesList = nuevaReserva ? [service] : ingreso.servicesList;
    const nuevoIngreso = {
      ...ingreso,
      guests: 0,
      roomsList,
      customersList,
      servicesList,
      vehiclesList
    }
    const puedeReservar = PuedeReservar(moment(ingreso.checkinEstimated).format('YYYY-MM-DD'), moment(ingreso.checkoutEstimated).format('YYYY-MM-DD'), { ...nuevoIngreso, roomsList: roomsList })
    if (estado != 'cancelada' && estado != 'checkout' && !puedeReservar.resultado && !sobrevender &&
      !window.confirm(`El lugar ${puedeReservar.habitacion.number} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
    }
    else {
      //Cambio el ingreso
      if (estado != nuevoIngreso.state) {
        nuevoIngreso.state = estado
        switch (estado) {
          case "checkin":
            nuevoIngreso.checkinMade = moment().format()
            nuevoIngreso.checkoutMade = null
            break;
          case "checkout":
            nuevoIngreso.checkoutMade = moment().format()
            break;
          case "confirmada":
            nuevoIngreso.state = "confirmada"
            nuevoIngreso.checkoutMade = null
            nuevoIngreso.checkinMade = null
            break;
          case "cancelada":
            nuevoIngreso.state = "cancelada"
            break;

        }
      }
      delete nuevoIngreso.nuevaReserva
      if (nuevaReserva) {
        setLoading(true)
        MandarFormularioDepto(
          nuevoIngreso,
          setLoading,
          dispatch,
          testFunction,
          makeBill,
          goToPayment, true)
      }
      else {
        CambiarEstadoReserva(nuevoIngreso, setLoading, onComplete, dispatch, null)
      }
    }
  }
}

//Tambien aca se carga la reserva editada
export const NuevaReserva = ({customerBilling,setCustomerBilling,diaPlaya,isAdmin, reserva: ingreso, dispatch, testFunction, nuevaReserva, isMobile, goToPayment, listaDeptos: listaDeptosTotal, withoutPlace }) => {
  const [listaDeptos, setListaDeptos] = useState([]);
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [fechaSalida, setFechaSalida] = useState("");
  const [estado, setEstado] = useState("");
  const [lugarElegido, setLugarElegido] = useState("");
  const [loading, setLoading] = useState(false);
  const [sobrevender, setSobrevender] = useState(false);
  const [argolia, setArgolia] = useState("");
  const [placesAutocomplete, setPlacesAutocomplete] = useState("");
  const [recargar, setRecargar] = useState(1);
  const [servicio, setServicio] = useState();
  const [hora, setHora] = useState('11:00');
  const [listIndex, setListIndex] = useState(0);
  const [costo, setCosto] = useState(0)
  const [makeBill, setMakeBill] = useState(true);
  const [openPago, setOpenPago] = useState(false);
  const [forma, setForma] = useState();
  const [onPay, setOnPay] = useState(()=>{});
  //const [habitacionesDisponible,setHabitacionesDisponible]= useState([]);
  const [componentes, setComponentes] = useState()
  const [state, dispatchHook] = useReducer(reducer, initialState)
  const escaner= useRef()
  useEffect(() => {
    if (moment(fechaSalida).diff(fechaIngreso, 'days') < 0) {
      setFechaSalida(moment(fechaIngreso).add(1, 'days').format('YYYY-MM-DD'))
    }
  }, [fechaSalida])
  useEffect(() => {
    dispatchHook({type:'INIT_CUSTOMERS_LIST',payload:ingreso.customersList})
    if (!isMobile){
      escaner.current.focus()
    }
    setEstado(ingreso.state)
    setHora(moment(ingreso.checkoutEstimated).add(3, 'hours').format("HH:mm"))
    setListaDeptos(ingreso.roomsList)
    setFechaIngreso(moment(ingreso.checkinEstimated).format("YYYY-MM-DD"))
    setFechaSalida(moment(ingreso.checkoutEstimated).format("YYYY-MM-DD"))
      var placesAutocomplete = places({
        appId: "plT9TSUAMCB9",
        apiKey: "df2addcb9735cb59bfdc55a75fe946e6",
        container: document.querySelector('#address-input')
      });
      placesAutocomplete.configure({
        type: 'city',
        aroundLatLngViaIP: false,
      })
      placesAutocomplete.on('change', (e) =>{
        delete e.suggestion.hit
        delete e.suggestion.rawAnswer
        delete e.suggestion.highlight
        setArgolia(e.suggestion)
      });
  
      if (ingreso.customersList && ingreso.customersList[0] && ingreso.customersList[0].geo) {
        const geo=JSON.parse(ingreso.customersList[0].geo) ? JSON.parse(ingreso.customersList[0].geo).value : '';
        placesAutocomplete.setVal(geo)
      }
      setPlacesAutocomplete(placesAutocomplete)
    setSobrevender(false)
  }, [ingreso]);

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);
    
    if(componentes){
      const extraComponents=Object.keys(componentes).reduce((acom,current,index)=>{
      if(EsLugar(current)||EsVehiculo(current)||EsComponenteExtra(current)){
      return{...acom,[current]:componentes[current]}}
      else{
        return acom;
      }
    
    },{})
      setComponentes({...componentesAux,...extraComponents})
    }
    else{
      setComponentes(componentesAux)

    }

  },[state])

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);
    
    if(componentes){
      const extraComponents=Object.keys(componentes).reduce((acom,current,index)=>{
      if(!EsVehiculo(current)){
      return{...acom,[current]:componentes[current]}}
      else{
        return acom;
      }
    
    },{})
      setComponentes({...componentesAux,...extraComponents})
    }
    else{
      setComponentes(componentesAux)

    }

  },[recargar])

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);

    if(componentes){
      const extraComponents=Object.keys(componentes).reduce((acom,current,index)=>{
      if(!EsLugar(current)){
      return{...acom,[current]:componentes[current]}}
      else{
        return acom;
      }
    
    },{})
      setComponentes({...componentesAux,...extraComponents})
    }
    else{
      setComponentes(componentesAux)

    }

  },[listaDeptos])


  useEffect(()=>{
    if(argolia){
      dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,geo:JSON.stringify(argolia)}})
    }
  },[argolia])

  useEffect(() => {
    if (window.scaner) {
      window.scaner.onUnduplicatedRead = (txt, result) => {
        try {
          const parserta = ParseScan(txt);

          if (parserta.fullName && parserta.dni && parserta.bday && state.customersList.filter(x => x.dni == parserta.dni).length == 0) {
            const birthdate = parserta.bday.split('/')[2] + "-" + parserta.bday.split('/')[1] + "-" + parserta.bday.split('/')[0]
            alert(`Nombre: ${parserta.fullName}\nSexo: ${parserta.sex}\nDni: ${parserta.dni}\nCumpleaños: ${birthdate}\n\nPersonas: ${state.customersList.length + 1}`)
            const customer= {
              fullName: Normalizar(parserta.fullName),
              dni: parserta.dni,
              birthdate: moment(birthdate).format(),
              age:moment().diff(birthdate,"years")
            }
              const uniqueId=parserta.dni;
              dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,uniqueId,...customer}})
              dispatchHook({type:"ADD_CUSTOMER"})
          }
        }
        catch (error) {
          alert("No se reconoce correctamente el formato del documento")
        }
      };
    }
  }, [state.customersList]);
  const noches = moment(fechaSalida).diff(moment(fechaIngreso), "days")
  const vehiculos = ingreso.vehiclesList ? ingreso.vehiclesList : [];
  const setVehiculos = (a) => { ingreso.vehiclesList = a; setRecargar(Math.random() * 1000000) };
  const onComplete = () => { goToPayment() }
  const habitacionesDisponible = useMemo(() => HabitacionesDisponibles(fechaIngreso, fechaSalida), [fechaIngreso,fechaSalida]) 
  const habitacionesDisponiblesInput = useMemo(() => habitacionesDisponible.filter(x=>!listaDeptos.find(y=>x.number==y.number)).map(x=>({id:x.roomId,text:x.number,value:x.number})), [habitacionesDisponible,listaDeptos]) 
  const listaHorarios = GetListaHorarios();
  const InsertarDepto=useCallback(
    (deptoNumber) => {
    if (habitacionesDisponible.find(x => x.number == deptoNumber)) {
        setListaDeptos([...listaDeptos.filter(x => x.number != deptoNumber), habitacionesDisponible.find(x => (x.number == deptoNumber))])
      }
      else if (listaDeptosTotal.find(x => x.number == deptoNumber)) {
        if (window.confirm(`El lugar ${deptoNumber} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
          setSobrevender(true)
          setListaDeptos([...listaDeptos.filter(x => x.number != deptoNumber), listaDeptosTotal.find(x => x.number == deptoNumber)])
        }
      }
      else {
        alert(`El lugar ${deptoNumber} no es un lugar valido`)
      }
    },
    [listaDeptos,habitacionesDisponible],
  )

  const CorroborarRegistros=useCallback((lugarElegido,listaDeptos)=>{
    if (state.customersList.length == 0){
      alert('Debes cargar al menos una persona al registro')
      return false
    }
    else if (state.customersList.filter(x=>x.fullName.trim()!="").length == 0) {
      alert("Todas las personas deben tener nombre")
      return false
    }
    else if (!withoutPlace&&listaDeptos.length == 0&&lugarElegido=="") {
      alert('Falta poner lugar')
      return false
    }
    else if (!withoutPlace&&lugarElegido==""){
      return true;
    }
    else if (lugarElegido==""){
      return true;
    }
    else if(lugarElegido!=""&&
    (window.confirm(`El lugar ${lugarElegido} quedó pendiente de ser seleccionado ¿Desea agregarlo?`))){
      if (habitacionesDisponible.find(x => x.number == lugarElegido)) {
        setListaDeptos([...listaDeptos.filter(x => x.number != lugarElegido), habitacionesDisponible.find(x => (x.number == lugarElegido))])
        setLugarElegido('')
        return false;
      }
      else if (listaDeptosTotal.find(x => x.number == lugarElegido)) {
        if (window.confirm(`El lugar ${lugarElegido} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
          setSobrevender(true)
          setListaDeptos([...listaDeptos.filter(x => x.number != lugarElegido), listaDeptosTotal.find(x => (x.number == lugarElegido))])
          setLugarElegido('')
          return false;
        }
        else if(withoutPlace){
         return true
        }
        else{
          alert('Falta poner lugar')
          return false
        }
      }
      else {
        alert(`El lugar ${lugarElegido} no es un lugar valido`)
        return false;
      }  
    }
    
  },[habitacionesDisponible,listaDeptosTotal,withoutPlace,state.customersList])
  const customersToBill=state.customersList.filter(x=>x.dni)
  const reloadCustomeBilling = useCallback(() => {
    if (customersToBill.length>0){
      setCustomerBilling(customersToBill.sort((a,b)=>{ return moment(a.birthdate).diff(moment(b.birthdate)) })[0])
    }
     else{
      setCustomerBilling(null)
     } 
    }
    , [state.customersList])
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Agregando'
    >
      {openPago&&<ConfirmarPago 
      customerBilling={customerBilling}
      setCustomerBilling={setCustomerBilling}
      customersList={customersToBill.length>0?customersToBill:[]}
      pagarLuego={true} newReservation={nuevaReserva} onPay={onPay} loading={loading} setForma={setForma} makeBill={makeBill} setMakeBill={setMakeBill} isMobile={isMobile} precio={costo} 
      open={openPago} setOpen={setOpenPago} nombreCuenta={state.customersList.length>0?state.customersList[0].fullName:""} 
      fechaSalida={fechaSalida} noches={servicio.quantity} forma={forma} descripcion={servicio?.description} listaDeptos={listaDeptos}/>}
      <Row>
        {isMobile&&<Col xs={12}>
          <ButtonSemantic disabled={window.scaner == undefined} color='teal' style={{ fontSize: "18px", paddingTop: "10px", paddingBottom: "10px" }} fluid onClick={() => {
            window.scaner.show()
            window.scaner.onUnduplicatedRead = (txt, result) => {
              try {
                const parserta = ParseScan(txt);
                if (parserta.fullName && parserta.dni && parserta.bday) {
                  const birthdate = parserta.bday.split('/')[2] + "-" + parserta.bday.split('/')[1] + "-" + parserta.bday.split('/')[0]
                  alert(`Nombre: ${parserta.fullName}\nSexo: ${parserta.sex}\nDni: ${parserta.dni}\nCumpleaños: ${birthdate}\n\nPersonas: ${state.customersList.filter(x => x.dni != parserta.dni).length + 1}`)
                  const customer= {
                    fullName: Normalizar(parserta.fullName),
                    dni: parserta.dni,
                    birthdate: moment(birthdate).format(),
                    age:moment().diff(birthdate,"years")
                  }
                    const uniqueId=Math.random();
                    const foundCustomer=state.customersList.find(x=>x.dni==parserta.dni)
                    if (foundCustomer) {
                      dispatchHook({ type: 'REMOVE_CUSTOMER', payload: foundCustomer })
                      dispatchHook({type:"UPDATE_CUSTOMER",payload:{...foundCustomer,birthdate:customer.birthdate,fullName:customer.fullName}})

                    }
                    else{
                      dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,uniqueId,...customer}})
                    }
                    dispatchHook({type:"ADD_CUSTOMER"})

                }
              }
              catch (error) {
                alert("No se reconoce correctamente el formato del documento")
              }
            };
          }}>Escanear DNI</ButtonSemantic>
        </Col>}
        {/*<Col xs={6} md={6}>
          <ButtonSemantic color='teal' style={{ fontSize: "18px", paddingTop: "10px", paddingBottom: "10px" }} fluid onClick={() => {
            setOpenScan(true)
          }}>Escanear Pasaporte</ButtonSemantic>
        </Col>*/}
      </Row>
      <Row>
      {!isMobile&&<Col xs={12}>
        <input 
          className={'inputHidden'}
          autoComplete="off"
          autocomplete="false" 
          id="dasfafa"
          name="sfafasfa"
          ref={escaner}
          onBlur={()=>dispatchHook({type:'SET_SCANER',payload:false})}
          onFocus={()=>dispatchHook({type:'SET_SCANER',payload:true})}
          onKeyPress={(e)=>{
            if(e.charCode == 13){
              const parserta = ParseScan(e.target.value);  
              const birthdate = parserta.bday?parserta.bday.split('-').join('/').split('/')[2] + "-" + parserta.bday.split('-').join('/').split('/')[1] + "-" + parserta.bday.split('-').join('/').split('/')[0]:'' 
              const customer={fullName: Normalizar(parserta.fullName), dni:parserta.dni, birthdate,age:moment().diff(birthdate,"years")};
              const foundCustomer=state.customersList.find(x=>x.dni==parserta.dni)
              if (foundCustomer) {
                dispatchHook({type:"REMOVE_CUSTOMER",payload:foundCustomer})
                dispatchHook({type:"UPDATE_CUSTOMER",payload:{...foundCustomer,birthdate:customer.birthdate,fullName:customer.fullName}})

              }
              else{
                dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,...customer}})
              }
              e.target.value=""
              dispatchHook({type:"ADD_CUSTOMER"})
            }
          }}
          />
        </Col>}
      <Col xs={12} md={8}>
      <Row>
      <Col xs={12} md={4}> <h3>Nombre y Apellido</h3><Input 
        autocomplete="off"
        value={state.customer.fullName??""} 
        onChange={(e) => {
            dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,fullName:Normalizar(e.target.value)}})          
          }} 
        name="nombre" id={"nombre"}></Input></Col>
        <Col xs={12} md={4}>
          <h3>Documento</h3>
          <Input autocomplete="off" value={state.customer.dni??""}
          onKeyPress={(target)=>{
          if(target.charCode == 13){
            dispatchHook({type:"ADD_CUSTOMER"})
          }}}
          onChange={(e) => dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,dni:e.target.value}})} 
          name="dni" id={"dni"}></Input>
        </Col>
        <Col xs={12} md={2}> 
          <h3>Edad</h3>
          <Input autocomplete="off" id="edad" name="edad" 
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatchHook({type:"ADD_CUSTOMER"})
            }}}
          onChange={(e) => dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,age:e.target.value,birthdate:moment().subtract(e.target.value, 'years').format()}})} 
          type="number" pattern="[0-9]*" value={state.customer.age??""}></Input></Col>
        {(!isMobile||!diaPlaya)&&<Col xs={12} md={4}> 
          <h3>Email</h3>
          <Input autocomplete="off"
          id={'email'}
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatchHook({type:"ADD_CUSTOMER"})
            }}}  
          onChange={(e) => {
            const emailVal=e.target.value.trim()!=""?e.target.value.trim():null;
            dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,email:emailVal}})}}
          value={state.customer.email??""}></Input></Col>}
        <Col xs={12} md={4}> 
          <h3>Telefono</h3>
          <Input 
          id={'telefono'}
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatchHook({type:"ADD_CUSTOMER"})
            }}}
          onChange={(e) => dispatchHook({type:"UPDATE_CUSTOMER",payload:{...state.customer,phone:e.target.value}})}
          autocomplete="off"  type={'number'} pattern="[0-9]*+" value={state.customer.phone??""}></Input>
        </Col>
        <Col xs={12} md={4} style={{display:(!isMobile||!diaPlaya)?'':'none'}}> <h3>Procedencia</h3><Input 
        onKeyPress={(target)=>{
          if(target.charCode == 13){
            dispatchHook({type:"ADD_CUSTOMER"})
          }}}
        autocomplete="off" type="search"  id="address-input" /></Col>
         </Row>
      </Col>
      <Col xs={12} md={4}>
      {!isMobile && <br></br>}
         <Col xs={12} md={6} className={"d-flex align-items-center m-auto"} >
          <br></br>
          <br></br>
          <ButtonSemantic.Group vertical={isMobile} style={{ margin: "auto" }} widths={ingreso.state == 'confirmada' ? '3' : '2'}>
            <ButtonSemantic circular basic icon={state.scanerReady?'barcode':'add user'} color={"teal"}  className={'fz-40'} onClick={() => {
              dispatchHook({type:"ADD_CUSTOMER"})
              escaner.current.focus()
            }}></ButtonSemantic>
            </ButtonSemantic.Group>
          </Col>
        </Col>
      </Row>
        <br></br>
        <Row>
        <Col xs={12} md={6}><h3>Personas ({state.customersList.length})</h3>
          <TablaPersonas data={JSON.parse(JSON.stringify(state.customersList))}
              dispatchHook={dispatchHook} listIndex={listIndex} recargar={() => setRecargar(Math.random() * 1000000)} setListIndex={setListIndex} setArgolia={placesAutocomplete.setVal}></TablaPersonas>
        </Col>
        <Col xs={12} md={6}><h3>Vehiculos ({vehiculos.length})</h3>
          <TablaVehiculos data={vehiculos} setData={setVehiculos} reservationId={ingreso.reservationId}></TablaVehiculos>
        </Col>
        </Row>

      {!nuevaReserva&&<Row>
        <Col xs={12} md={{ size: 3, offset: 1 }}> <h3>Fecha entrada</h3><Input type="date" value={fechaIngreso} onChange={(e) => { setFechaSalida(moment(e.target.value).add(1, 'days').format('YYYY-MM-DD')); setFechaIngreso(e.target.value) }}></Input></Col>
        <Col xs={12} md={{ size: 3 }}> <h3>Fecha salida</h3><Input type="date" value={fechaSalida} onChange={(e) => setFechaSalida(e.target.value)}></Input></Col>
        <Col xs={12} md={{ size: 2 }}> <h3>Noches</h3><Input autocomplete="off" style={{ fontWeight: '800', textAlign: 'center' }} type="number" pattern="[0-9]*" value={noches} onChange={(e) => setFechaSalida(moment(fechaIngreso).add(e.target.value, 'days').format('YYYY-MM-DD'))}></Input></Col>
        <Col xs={12} md={{ size: 2}}> <h3>Hora de salida</h3><Input type="select" value={hora} onChange={(e) => setHora(e.target.value)}>{listaHorarios.map(x => <option>{x}</option>)}</Input></Col>
      </Row>}

      <br></br>

      <br></br>
      {nuevaReserva&&<Precios 
        vistaCompleta={(!isMobile||!diaPlaya)}
        setHora={setHora}
        hora={hora}
        listaHorarios={listaHorarios}
        fechaIngreso={fechaIngreso} setFechaIngreso={setFechaIngreso}
        modificarFechaIngreso={true} servicesList={[]} ingreso={ingreso} componentes={componentes} 
        setComponentes={setComponentes} setFechaSalida={setFechaSalida} setServicio={setServicio} 
        costo={costo} setCosto={setCosto}/>}
      <br></br>
      {(!isMobile||!diaPlaya)&&<Row>
        <Col xs={12} md={{ size: 3, offset: 3 }}>
          <h3>{`Lugares (${listaDeptos.length})`}</h3>
          {listaDeptos.map(x => (<a href="#" onClick={() => { SacarDeLista(listaDeptos, setListaDeptos, x) }} className="and btand btn btn-info" style={{ margin: '0px 7px 7px',paddingRight:'5px', overflow: 'hidden' }}>{x.number + ""}<Icon style={{marginLeft:'10px'}} name="x icon" /></a>))}
        </Col>
        {/*<Col xs={6} md={2}><h3>{ingreso.roomsList[0].number}</h3></Col>*/}
        <Col xs={12} md={2}>
          <h3>{`Disponibles (${habitacionesDisponiblesInput.length})`} </h3>
          <Dropdown 
          className="dropdownx"
          placeholder='Disponibles' search selection value={''} 
          fluid
          clearable
          search={(lista, value) =>{
            //filter list to know if any item in list starts with value
            return lista.filter(x => x.value.toLowerCase().startsWith(value.toLowerCase()))
          }}
          selectOnBlur={false}
          allowAdditions={true}
          additionLabel={'Agregar '}
          
          onChange={(e,data)=>InsertarDepto(data.value.trim())} 
          //If all items in habDisponiblesInput are numbers sort by number
          options={habitacionesDisponiblesInput.sort((a,b)=>{
            if(!isNaN(a.value)&&!isNaN(b.value)){
              return a.value-b.value
            }
            return a.value.localeCompare(b.value)
          })}
          />
          {/* <Input type={'select'} onChange={(e) => { setListaDeptos([...listaDeptos.filter(x => x.roomId != e.target.value), habitacionesDisponible.find(x => (x.roomId == e.target.value))]) }}>
            {habitacionesDisponible.map((x, i) => <option key={i} value={x.roomId}>{x.number}</option>)}
          </Input> */}
        </Col>
        {/* <Col xs={6} md={{ size: 2 }}>
          <h3>Insertar</h3>
          <Input value={lugarElegido} onKeyPress={(target) => {
            if (target.charCode == 13) {
              InsertarDepto(target.target.value)
            }
          }} onChange={(e) => setLugarElegido(e.target.value)}></Input></Col> */}
      </Row>}
      <br></br>

      <Container>
        <Row>
          <Col style={{ display: "contents" }}>
            <ButtonSemantic.Group vertical={isMobile} style={{ margin: "auto" }} widths={'5'}>
              {!nuevaReserva &&ingreso.state!='preingreso'&& <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='teal' disabled={loading} onClick={() => {
                if (CorroborarRegistros(lugarElegido,listaDeptos)) {
                  dispatchHook({type:"ADD_CUSTOMER"})
                  MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                }
              
              }}>{'Guardar'}</ButtonSemantic>}
              {!isMobile && !nuevaReserva &&ingreso.state!='preingreso'&& <ButtonSemantic.Or text={"o"} />}

              {((isAdmin && ingreso.state != 'confirmada') || (nuevaReserva)||ingreso.state=='preingreso') && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='blue' disabled={loading}
                onClick={() => {
                  const estado = 'confirmada';
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (nuevaReserva || window.confirm(`¿Cambiar el estado a reserva?`))) {

                      const funct= (formaPago,listaDeptos,makeBillAux)=>
                      {
                        if (openPago&&formaPago=="") {
                          alert("falta agregar forma de pago")
                        }
                        else if(formaPago){
                          dispatchHook({type:"ADD_CUSTOMER"})
      
                          MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,{...servicio,paymentsList:formaPago=="Pagar Luego"?[]:[{...servicio.paymentsList[0],method:formaPago}]}, setLoading, dispatch, testFunction,makeBillAux, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                        }
                      }
                      if (!diaPlaya&&nuevaReserva) {
                        setOpenPago(true)
                        reloadCustomeBilling()
                        setOnPay(()=>funct)
                      }
                      else{
                        dispatchHook({type:"ADD_CUSTOMER"})
    
                        MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio==undefined?"":{...servicio,paymentsList:{...servicio.paymentsList[0],method:'Efectivo'}}, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                      }
                  }
                }}>Reserva</ButtonSemantic>}
              {!isMobile && ingreso.state != 'checkin' &&<ButtonSemantic.Or text={"o"} />}
              {ingreso.state != 'checkin' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='green' disabled={loading}
                onClick={() => {
                  const estado = 'checkin'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    const funct= (formaPago,listaDeptos,makeBillAux)=>
                    {
                      if (!formaPago) {
                        alert("falta agregar forma de pago")
                      }
                      else if(formaPago){
                        dispatchHook({type:"ADD_CUSTOMER"})
    
                        MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,{...servicio,paymentsList:formaPago=="Pagar Luego"?[]:[{...servicio.paymentsList[0],method:formaPago}]}, setLoading, dispatch, testFunction,makeBillAux, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                      }
                    }
                    if (!diaPlaya&&nuevaReserva) {
                      setOpenPago(true)
                      reloadCustomeBilling()
                      setOnPay(()=>funct)
                    }
                    else{
                      dispatchHook({type:"ADD_CUSTOMER"})
  
                      MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio==undefined?"":{...servicio,paymentsList:{...servicio.paymentsList[0],method:'Efectivo'}}, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                    }
                }}}>CheckIn</ButtonSemantic>}
              {!isMobile && ingreso.state != 'abierta'&& (ingreso.state == 'confirmada') &&<ButtonSemantic.Or text={"o"} />}
              {ingreso.state != 'abierta' && (ingreso.state == 'confirmada') && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='purple' disabled={loading}
                onClick={() => {
                  const estado = 'abierta'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                      dispatchHook({type:"ADD_CUSTOMER"})
                      MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio==undefined?"":{...servicio,paymentsList:{...servicio.paymentsList[0],method:'Efectivo'}}, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                }}}>Abierta</ButtonSemantic>}
                
              {!isMobile && !nuevaReserva && ingreso.state != 'confirmada' && ingreso.state != 'checkout' && ingreso.state != 'preingreso' && <ButtonSemantic.Or text={"o"} />}
              {!nuevaReserva && ingreso.state != 'confirmada' && ingreso.state != 'checkout'&& ingreso.state != 'preingreso' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='orange' disabled={loading}
                onClick={() => {
                  const estado = 'checkout'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                  (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    dispatchHook({type:"ADD_CUSTOMER"})

                    MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                  }
                }}>Checkout</ButtonSemantic>}
              {!isMobile && ((!nuevaReserva && isAdmin)) && ingreso.state != 'cancelada' && <ButtonSemantic.Or text={"o"} />}
              {((!nuevaReserva && isAdmin)) && ingreso.state != 'cancelada' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='grey' disabled={loading}
                onClick={() => {
                  const estado = 'cancelada'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                  (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    dispatchHook({type:"ADD_CUSTOMER"})
                    MandarIngreso(listaDeptos, state.customersList, vehiculos, fechaIngreso, fechaSalida, ingreso, hora, estado, nuevaReserva,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,lugarElegido,habitacionesDisponible,listaDeptosTotal)
                  }
                }}>Cancelada</ButtonSemantic>}
      )
      </ButtonSemantic.Group>
            <br></br>

          </Col>
        </Row>
        <br></br>
      </Container>
    </LoadingOverlay>

  )
}

export default connect(state => ({ diaPlaya: state.mainReducer.diaPlaya, 
  isAdmin: state.mainReducer.isAdmin, 
  withoutPlace: state.mainReducer.withoutPlace, 
  listaDeptos: state.mainReducer.listaDeptos, 
  listaPrecios: state.mainReducer.listaPrecios, 
  informacionHotel: state.mainReducer.informacionHotel,
  isMobile: state.mainReducer.isMobile }), null)(NuevaReserva)

